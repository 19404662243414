import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch.css";
import CivilBatch from '../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import Radium from "radium";
import Loader from '../Loader/Loader';

// import { BASE_URL } from '../../insightConfig/insightConfig';
// import CourseShareComponent from '../CourseShareComponent/CourseShareComponent';
import { fetchCourseData, fetchCurrentNewsData, fetchCourseSubjects } from './api';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';
function DynamicCourse() {
    const { coursename } = useParams();
    const [courseData, setCourseData] = useState(null);
    const [reloadCourseData, setReloadCourseData] = useState(false);
    const [newsData, setNewsData] = useState([]);
    const location = useLocation();






    // const formatDate = (dateString) => {
    //     // Create a Date object from the dateString
    //     const date = new Date(dateString);
    //     // Get the day, month, and year
    //     const day = date.getDate();
    //     const monthIndex = date.getMonth();
    //     const year = date.getFullYear();
    //     // Define the month names array
    //     const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];
    //     // Format the date string
    //     const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
    //     return formattedDate;
    // };
    const storedAuthToken = localStorage.getItem('authtoken');
    useEffect(() => {
        const fetchData = async () => {
            const courseResponse = await axios.get(BASE_URL + `/course/${coursename}/${storedAuthToken}`)
            setCourseData(courseResponse.data.data[0])
            const newRepsonse = await axios.get(BASE_URL + `/current-affairs/News`)
            setNewsData(newRepsonse.data.data)
        }
        fetchData();
    }, [coursename, reloadCourseData]);
    console.log(courseData)
    if (!courseData) {
        return <div style={{ backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed", top: "0" }}><Loader /></div>
    }
    const componentName = "civilHistory";
    // const p = courseData?.Price || 0;
    // const displayPrice = Math.round(p * (100 - courseData?.Discount) * 0.01);
    // const formattedDate = formatDate(courseData?.Bdate);

    // const urlString = classData.SyllabusPdf;
    // let correctedURL = "#";
    // if (courseData.SyllabusPdf) {
    //     const parts = courseData.SyllabusPdf.split("/");
    //     const filename = parts[parts.length - 1];
    //     const encodedFilename = encodeURIComponent(filename);
    //     correctedURL = parts.slice(0, -1).join("/") + "/" + encodedFilename;
    // }



    // const paragraphs = courseData.Description || [];

    const courseStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage: `url(${courseData.Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(25px)",
            zIndex: "1",
        }
    }
    return (
        <>
            <Helmet>
                <title>{courseData.MetaTitle}</title>
                <meta name="description" content={`${courseData.MetaDescription}`}></meta>
                <meta property="og:title" content={courseData.MetaTitle} />
                <meta property="og:image" content={courseData.Image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={courseData.MetaDescription} />
            </Helmet>

            {/* Civil GS Foundation Section Start */}
            <section className={`${coursename}-background dynamic-course`}>
                <section style={courseStyle.before} className='dynamic-course-before' />
                {/* Civil Batch Section Start */}
                <CivilBatch courseData={courseData} reloadCourseData={reloadCourseData} setReloadCourseData={setReloadCourseData} />
                {/* Civil Batch Section End */}
            </section>
            <CivilButton courseData={courseData} newsData={newsData} componentName={componentName} />
            {/* Civil GS Foundation Section End */}
        </>
    )
}

export default Radium(DynamicCourse); 