import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { IoMdArrowDropright } from "react-icons/io";
import { useQuizDetails } from './QuizContext';

function Homequizmenu({ subjects }) {
    const { quizDetails, setQuizDetails } = useQuizDetails();
    const [openSubject, setOpenSubject] = useState(null);

    const handleToggle = (subjectId) => {
        setOpenSubject(openSubject === subjectId ? null : subjectId);
    };

    const handleChangeSection = (subjectId, subjectURL, subject, sectionId, sectionURL, section) => {
        setQuizDetails((preData) => ({
            ...preData, subjectId, subjectURL, subject, sectionId, sectionURL, section
        }))

    }

    return (
        <>
            <div className="card">
                <div className="card-header list-background py-3 fs-5 text-white">
                    <RxDashboard className='me-2 mb-1' />
                    Select Your Subject
                </div>
                <ul className="list-group list-group-flush">
                    {subjects?.map((subject) => {
                        const sections = subject.sections || [];
                        return (
                            <div key={subject.subjectId}>
                                <Link
                                    className="list-group-item d-flex justify-content-between fw-bold list-container"
                                    onClick={() => handleToggle(subject.subjectId)}
                                    to="#"
                                >
                                    {subject.subject} {openSubject === subject.subjectId ? <FaMinus className='list-icon my-1 p-1' /> : <FaPlus className='list-icon my-1 p-1' />}
                                </Link>
                                {openSubject === subject.subjectId && sections.length > 0 && (
                                    <div className='p-3'>
                                        <ul style={{ listStyleType: "none", display: "flex", flexDirection: "column", padding: "0px" }}>
                                            {sections?.map((section) => (
                                                <li key={section.sectionId} className=''>
                                                    <Link to={`/upsc-quiz/${subject.subjectURL}/${section.sectionURL}`} /* onClick={() => handleChangeSection(subject.subjectId, subject.subjectURL, subject.subject, section.sectionId, section.sectionURL, section.section)}  */className='list-group-item'><IoMdArrowDropright className="text-orange fs-4" />{section.section}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </ul>
            </div>
        </>
    )
}

export default Homequizmenu;