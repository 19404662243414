import axios from "axios";
import { BASE_URL } from "../insightConfig/insightConfig";
import Cookies from "universal-cookie";
import { saveCookie } from "./cookieService";
const cookies = new Cookies();

// Set multiple cookies for demonstration




export function saveUserActivity(event, page, pageUrl) {
    const allCookies = cookies.getAll();
    const { name, email, contact, deviceId,IP } = allCookies;
    try {
        axios.post(BASE_URL + '/saveUserActivity', { event, page, pageUrl, name, email, contact, deviceId,IP })
    } catch (error) {
        console.log(error);
    }

}
export function setIPAdderess() {
    axios.get('https://api.ipify.org?format=json')
        .then(response => {
            saveCookie("IP", response.data.ip);
        })
        .catch(error => {
            console.error("Error fetching IP address:", error);
        });

}