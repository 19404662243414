import React, { useEffect, useState } from 'react';
import "./Reportcard.css";
import "../../";
import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import fav from "../../Image/aboutimg.png";
import fav1 from "../../Image/favicon1.png"
import user from "../../Image/user.png";
import { BASE_URL, BASE_URL1 } from '../../insightConfig/insightConfig';
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';


const Reportcard1 = () => {
    const { state: { testReport, testData } } = useLocation()
    const storedAuthToken = localStorage.getItem('authtoken');

    console.log('testData', testData)
    console.log('testReport', testReport)


    const [report, setreport] = useState([])
    const [reportQuestion, setReportQuestion] = useState([])
    const [reportAnserSheet, setReportAnserSheet] = useState(JSON.parse(testReport?.answersheet))
    console.log(reportAnserSheet)

    useEffect(() => {
        axios.get(`${BASE_URL}/loadTestQuestions/${testData.testSeriesSn}/${testData.PackageID}/${storedAuthToken}`).then((response) => {
            let finalQuestionArray = response.data.questions.map((questionOBJ) => {
                let currentAnserSheetOBJ = reportAnserSheet?.find(obj => obj.questionNumber == questionOBJ.QNo);
                return { ...questionOBJ, selectedOption: currentAnserSheetOBJ?.selectedOption, correctOption: currentAnserSheetOBJ?.correctOption }
            })
            setReportAnserSheet(finalQuestionArray)
        }).catch((error) => {
            console.log(error)
        })
    }, []);

    console.log(reportAnserSheet)

    let reportArray = reportQuestion;
    // if (!reportArray) {
    //     return <></>
    // }



    const renderhtml = (paragraphs) => {
        return <p className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
    };

    const renderhtml1 = (paragraphs) => {
        return <b className='renderhtml1' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
    };
    const selectedResponseIcon = (optionPosition, selectedOption, correctOption) => {
        if ((optionPosition == correctOption)) {
            if (selectedOption != '') {
                return <TiTick size={30} color='green' className='ms-2' />
            }

        } else {
            if (selectedOption == optionPosition && correctOption != selectedOption) {
                return <RxCross2 size={26} color='red' className='ms-2' />
            }
        }
    }
    let logo = 'https://insightdelhi.com/images/favicon.ico'

    return (
        <>
            <div className="container-fluid  bg-gray Reportcard-background d-flex justify-content-center align-items-center">
                <div className='container p-0'>
                    <div className='row mx-md-5'>
                        <div className='col-lg-12' >
                            <div className='p-1 pt-0 d-flex' style={{ backgroundImage: "linear-gradient(to right, rgb(155, 0, 0), rgb(109, 7, 97))", textDecoration: "none" }}>
                                <Link to='/testseries/finalresult' state={{ testData }} style={{ textDecoration: "none" }} className='text-white py-2 fs-4 px-4' >
                                    <FaArrowLeftLong />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='d-flex justify-content-center align-itmes-center' style={{ width: "100", backgroundImage: "linear-gradient(to right, rgb(155, 0, 0), rgb(109, 7, 97))" }}>
                                <div className='w-60 text-center' >
                                    <img style={{ width: "110px" }} src={logo} alt="" onError={(e) => e.target.src = fav1} />
                                    <h3 className='py-2 fs-5' style={{ borderBottom: "1px solid #ccc", fontWeight: "900", color: "#FFC" }}>DETAILED PERFORMANCE REPORT</h3>
                                    <p style={{ fontWeight: "bold", color: "white", padding: "1vmax", letterSpacing: ".09rem" }}>{testData?.Title}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='d-flex py-3 justify-content-center flex-column align-items-center' style={{ backgroundImage: "linear-gradient(to right, #fff7b1, #c4f7f0)" }}>
                                <img style={{ width: "120px", height: "120px" }} src={user} alt="" />
                                <div className="d-flex fs-md-5 fs-6 my-2 justify-content-between align-items-center" style={{ backgroundColor: "#ff5722", borderRadius: "5px", padding: "10px", color: "white", fontSize: "18px", fontWeight: "600", width: "95%" }}>
                                    {
                                        (testReport?.allIndiaRank && testReport?.percentile) && <>
                                            <span >All India Rank: {testReport?.allIndiaRank || 'NA'}</span>
                                            <span>Percentile: {testReport?.percentile || 'NA'}</span>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='py-3' style={{ backgroundImage: "linear-gradient(to right, #fff7b1, #c4f7f0)" }}>
                                <div className='row mx-auto '>
                                    <div className='col-lg-4 col-md-6 col-sm-12 '>
                                        <div className="text-center p-5 py-4 bg-light text-success my-2 fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-dark'>{testReport?.correct}</strong>
                                            <hr className='mb-2 mt-2' />
                                            CORRECT
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className="text-center p-5 py-4 bg-light text-success my-2 fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-dark'>{testReport?.wrongquestions}</strong>
                                            <hr className='mb-2 mt-2' />
                                            INCORRECT
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className="text-center p-5 py-4 bg-light text-success my-2 fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-dark'>{testReport?.totalquestion - testReport?.attempted}</strong>
                                            <hr className='mb-2 mt-2' />
                                            UNATTEMPTED
                                        </div>

                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className="text-center p-5 py-4 bg-light text-success my-2 fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-dark'>{testReport?.testcorrectmarks.toFixed(2)}</strong>
                                            <hr className='mb-2 mt-2' />
                                            POSITIVE MARKS
                                        </div>

                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className="text-center p-5 py-4 bg-light text-success my-2  fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-dark'>{testReport?.testwrongmarks.toFixed(2)}</strong>
                                            <hr className='mb-2 mt-2' />
                                            NEGATIVE MARKS
                                        </div>

                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                        <div className="text-center p-5 py-4 bg-success text-light my-2 fs-6" style={{ borderRadius: "9px", border: "1px solid #ccc", fontWeight: "600", fontSize: "18px", borderBottom: "3px solid green" }}>
                                            <strong className='text-white'>{(testReport?.testcorrectmarks?.toFixed(2) - testReport?.testwrongmarks)?.toFixed(2)}</strong>
                                            <hr className='mb-2 mt-2' />
                                            NET SCORE
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="bg-light p-md-4 p-1">
                                <div className="row text-start">
                                    {reportAnserSheet?.map((item, index) => (
                                        <div className="col-md-12 pb-1 mb-0" style={{ fontWeight: "" }}>
                                            <div className="d-flex">
                                                <div style={{ backgroundColor: "#ff5722", fontWeight: "500", minWidth: "30px", height: "30px" }} className='rounded-5 text-white p-1 text-center'>{item?.QNo}</div>
                                                <div className="" style={{ fontSize: '14px' }}>
                                                    <p className='m-3 mt-2 '>{renderhtml(item?.Question)}</p>
                                                    <ul className='answer-list-test-report ps-md-3 ps-1 mb-0 pb-0'>
                                                        <li className='ps-0 ms-0'>
                                                            <span style={{ fontWeight: "500", marginRight: "7px" }}>(A) {' '}</span> {renderhtml(item?.OptionA)} {selectedResponseIcon('A', item.selectedOption, item.correctOption)}
                                                        </li>
                                                        <li className='ps-0 ms-0 '>
                                                            <span style={{ fontWeight: "500", marginRight: "7px" }}>(B) </span> {renderhtml(item?.OptionB)} {selectedResponseIcon('B', item.selectedOption, item.correctOption)}
                                                        </li>
                                                        <li className='ps-0 ms-0'>
                                                            <span style={{ fontWeight: "500", marginRight: "7px" }}>(C) </span> {renderhtml(item?.OptionC)} {selectedResponseIcon('C', item.selectedOption, item.correctOption)}
                                                        </li>
                                                        <li className='ps-0 ms-0 mb-0'>
                                                            <span style={{ fontWeight: "500", marginRight: "7px" }}>(D) </span> {renderhtml(item?.OptionD)} {selectedResponseIcon('D', item.selectedOption, item.correctOption)}
                                                        </li>
                                                    </ul>

                                                    <span
                                                        className="bg-success m-md-3 text-white p-1 "
                                                        style={{ fontWeight: "600" }}
                                                    >
                                                        Correct Answer: ({item.correctOption}) |
                                                        Your Answer: ({item.selectedOption || 'Not Selected'})
                                                    </span>



                                                    {/* <span className='bg-success m-md-3  text-white p-2 ' style={{ fontWeight: "600" }}></span> */}
                                                    {item?.Description ? <div style={{ backgroundColor:"#efe2db"}} className='ms-md-3 py-2 p-3 mt-3'><span className='fs-5 '>Explanation:</span> {renderhtml(item?.Description)}</div> : null}
                                                </div>
                                            </div>







                                        </div>
                                    ))
                                    }
                                </div>
                                <div className='p-3 d-flex justify-content-center'>
                                    <Link to='/testseries/finalresult' state={{ testData }} style={{ backgroundImage: "linear-gradient(to right, rgb(155, 0, 0), rgb(109, 7, 97))", textDecoration: "none" }} className='text-white py-2 rounded-pill fs-6 px-4' >
                                        Go Back
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reportcard1;
