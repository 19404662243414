import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CardsPage from '../../CardsPage/CardsPage';
import TwoRowThreeColumnLayout from '../../EffectonLoad/TwoThreecolumnLayout';
import { fetchCourseCard as fetchCoursesByExamBody } from '../../DynamicCourse/api';
import axios from 'axios';
import { BASE_URL1 } from '../../../insightConfig/insightConfig';

function CivilsCard({ setispayment }) {
  const civilheading = "CIVILS COURSES"
  const [testsData, setTestsData] = useState([]);
  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(BASE_URL1 + '/ExamBody/civils');
        console.log(response)

        setTestsData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
   
  }, []); 
  if (testsData.length === 0) {
    return <><TwoRowThreeColumnLayout /></>
  }

  const cards = testsData
  return (
    <>
      <Helmet>
        <title>Civils</title>
      </Helmet>
      <div>
        <CardsPage cards={cards} heading={civilheading} />
      </div>
    </>
  )
}

export default CivilsCard;