import React, { useEffect, useState } from 'react';
import "../Login/Login.css";
import logo from "../../Image/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { useAlert } from 'react-alert';
import { SignupUser } from '../DynamicCourse/api';
import Select from "react-select";
import { saveCookie } from '../../commonServices/cookieService';

function Signup({ setIsLoggedIn, prevPath, setAuthtoken, setispayment }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [examType, setExamType] = useState('civil services');
    const [city, setcity] = useState('Delhi');
    const [error, setError] = useState('');
    const alert = useAlert();
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const generateToken = (email) => {
        // Generate a unique token value based on user's email
        const token = email;
        return token;
    };

    //set user details in cookies even if he/she has not completed sign up 
    useEffect(() => {
        if (contact) {
            saveCookie('contact', contact);
        }
        if (email) {
            saveCookie('email', email);

        }
        if (name) {
            saveCookie('name', name);
        }
        if (examType) {
            saveCookie('examType', examType);
        }

    }, [name, contact, email, city, examType])

    const setCookie = (name, value) => {
        // Set the cookie with the token value
        document.cookie = `${name}=${value}`;
    };
    const handleRegistration = async () => {
        try {
            // Call the registerUser function with user data
            await SignupUser({ name, email, contact, password, examType, city });
            // If login is successful, generate a new token
            const token = generateToken(email);
            // Set the token as a cookie
            setCookie('token', token);
            // If registration is successful, reset form fields and show success message
            setName('');
            setEmail('');
            setContact('');
            setPassword('');
            setExamType('civil services');
            setcity('delhi')
            navigate('/');
            if (prevPath) {
                navigate(prevPath)
                setispayment(false)
            } else {
                navigate('/');
                setispayment(false)
            }
            setIsLoggedIn(true)
            setAuthtoken(token)
            localStorage.setItem('prevPath', prevPath);
            localStorage.setItem('authtoken', token);
            // alert("Hello, this is an alert!");
            alert.show('Registration Successfully');
            // setError('Registration successful!');
        } catch (error) {
            setError('User already exists. Please try again.');
        }
    };

    const citiesArray = [
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Hyderabad",
        "Ahmedabad",
        "Chennai",
        "Kolkata",
        "Surat",
        "Pune",
        "Jaipur",
        "Lucknow",
        "Kanpur",
        "Nagpur",
        "Indore",
        "Thane",
        "Bhopal",
        "Visakhapatnam",
        "Pimpri-Chinchwad",
        "Patna",
        "Vadodara",
        "Ghaziabad",
        "Ludhiana",
        "Agra",
        "Nashik",
        "Faridabad",
        "Meerut",
        "Rajkot",
        "Kalyan-Dombivli",
        "Vasai-Virar",
        "Varanasi",
        "Srinagar",
        "Aurangabad",
        "Dhanbad",
        "Amritsar",
        "Navi Mumbai",
        "Allahabad",
        "Ranchi",
        "Howrah",
        "Coimbatore",
        "Jabalpur",
        "Gwalior",
        "Vijayawada",
        "Jodhpur",
        "Madurai",
        "Raipur",
        "Kota",
        "Guwahati",
        "Chandigarh",
        "Solapur",
        "Hubballi-Dharwad",
        "Mysore",
        "Tiruchirappalli",
        "Bareilly",
        "Aligarh",
        "Tiruppur",
        "Gurgaon",
        "Moradabad",
        "Jalandhar",
        "Bhubaneswar",
        "Salem",
        "Mira-Bhayandar",
        "Warangal",
        "Guntur",
        "Bhiwandi",
        "Saharanpur",
        "Gorakhpur",
        "Bikaner",
        "Amravati",
        "Noida",
        "Jamshedpur",
        "Bhilai",
        "Cuttack",
        "Firozabad",
        "Kochi",
        "Nellore",
        "Bhavnagar",
        "Dehradun",
        "Durgapur",
        "Asansol",
        "Rourkela",
        "Nanded",
        "Kolhapur",
        "Ajmer",
        "Akola",
        "Gulbarga",
        "Jamnagar",
        "Ujjain",
        "Loni",
        "Siliguri",
        "Jhansi",
        "Ulhasnagar",
        "Navi Mumbai",
        "Mangalore",
        "Tirunelveli",
        "Malegaon",
        "Gaya",
        "Jalgaon",
        "Udaipur",
        "Maheshtala",
        "Davanagere",
        "Kozhikode",
        "Kurnool",
        "Rajpur Sonarpur",
        "Bokaro",
        "South Dumdum",
        "Bellary",
        "Patiala",
        "Gopalpur",
        "Agartala",
        "Bhagalpur",
        "Muzaffarnagar",
        "Bhatpara",
        "Panihati",
        "Latur",
        "Dhule",
        "Rohtak",
        "Korba",
        "Bhilwara",
        "Brahmapur",
        "Muzaffarpur",
        "Ahmednagar",
        "Mathura",
        "Kollam",
        "Avadi",
        "Kadapa",
        "Anantapur",
        "Kamarhati",
        "Bilaspur",
        "Sambalpur",
        "Shahjahanpur",
        "Satara",
        "Bijapur",
        "Rampur",
        "Shimoga",
        "Chandrapur",
        "Junagadh",
        "Thrissur",
        "Alwar",
        "Bardhaman",
        "Kulti",
        "Nizamabad",
        "Parbhani",
        "Tumkur",
        "Khammam",
        "Ozhukarai",
        "Bihar Sharif",
        "Panipat",
        "Darbhanga",
        "Bally",
        "Aizawl",
        "Dewas",
        "Ichalkaranji",
        "Tirupati",
        "Karnal",
        "Bathinda",
        "Jalna",
        "Eluru",
        "Barasat",
        "Purnia",
        "Bharatpur",
        "Sikar",
        "Unnao",
        "Amroha",
        "Farrukhabad",
        "Bidar",
        "Saugor",
        "Kanchipuram",
        "Dibrugarh",
        "Nagaon",
        "Haridwar",
        "Katihar",
        "Sambhal",
        "Shimla",
        "Tiruvannamalai",
        "Rewa",
        "Pali",
        "Baranagar",
        "Panvel",
        "Kulti",
        "Chittaurgarh",
        "Bilaspur",
        "Jammu",
        "Hapur",
        "Mirzapur",
        "Hathras",
        "Dharmavaram",
        "Chittoor",
        "Baidyabati",
        "Chilakaluripet",
        "Ghatal",
        "Palakkad",
        "Nagapattinam",
        "Gondal",
        "Port Blair",
        "Imphal",
        "Chhapra",
        "Pali",
        "Kumbakonam",
        "Naihati",
        "Raichur",
        "Parbhani",
        "Anantapur",
        "Etawah",
        "Kollam",
        "Shillong",
        "Budaun",
        "Vellore",
        "Alappuzha",
        "Puri",
        "Bansberia",
        "Mahbubnagar",
        "Raigarh",
        "Rewari",
        "Balurghat",
        "Nandyal",
        "Bettiah",
        "Krishnanagar",
        "Hindupur",
        "Hospet",
        "Nagda",
        "Nandurbar",
        "Surendranagar Dudhrej",
        "Purulia",
        "Hazaribag",
        "Adoni",
        "Medininagar",
        "Suryapet",
        "Tenali",
        "Karaikudi",
        "Tadepalligudem",
        "Karaikal",
        "Giridih",
        "Khora",
        "Bettiah",
        "Haldia",
        "Srirampore",
        "Dibrugarh",
        "Balasore",
        "Udgir",
        "Proddatur",
        "Suryapet",
        "Madanapalle",
        "Jalgaon",
        "Nandurbar",
        "Thanjavur",
        "Amalner",
        "Habra",
        "Pallavaram",
        "Kavali",
        "Chakdaha",
        "Saharsa",
        "Ongole",
        "Sehore",
        "Nandurbar",
        "Dhule",
        "Tenali",
        "Udgir",
        "Surendranagar",
        "Tiruchirappalli",
        "Suryapet",
        "Ponnur",
        "Pithapuram",
        "Palwancha",
        "Mangalagiri",
        "Mandapeta",
        "Macherla",
        "Lucknow",
        "Guntur",
        "Vizianagaram",
        "Rajam",
        "Nandigama",
        "Nandikotkur",
        "Markapur",
        "Kakinada",
        "Ongole",
        "Nandyal",
        "Madanapalle",
        "Kandukur",
        "Kadiri",
        "Adoni",
        "Anantapur",
        "Tadipatri",
        "Punganur",
        "Proddatur",
        "Pulivendula",
        "Pileru",
        "Peddapuram",
        "Nagari",
        "Kurnool",
        "Dharmavaram",
        "Chittoor",
        "Allagadda",
        "Akkarampalle",
        "Yemmiganur",
        "Visakhapatnam",
        "Srikakulam",
        "Rayadurg",
        "Madanapalle",
        "Manuguru",
        "Machilipatnam",
        "Kothagudem",
        "Kodad",
        "Kanchikacherla",
        "Kamareddy",
        "Jaggayyapeta",
        "Gudur",
        "Giddalur",
        "Gajuwaka",
        "Eluru",
        "Dharmavaram",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Punganur",
        "Proddatur",
        "Palamaner",
        "Ongole",
        "Nellore",
        "Mangalagiri",
        "Kovvur",
        "Kothapeta",
        "Hindupur",
        "Chilakaluripet",
        "Bhimavaram",
        "Anantapur",
        "Addanki",
        "Chirala",
        "Tirupati",
        "Srikalahasti",
        "Pungan"]


    return (
        <div className='loginContainer'>
            <div className='loginImage'>
                <Link to="/">
                    <img src={logo} alt='' />
                </Link>
            </div>
            <div className='loginForm'>
                <div className="card login-card">
                    <div className="card-header m-3 text-center fs-4 fw-500 text-white login-heading">
                        Create an Account
                    </div>
                    <div className="card-body">
                        <form>
                            <div className='signup-inputs'>
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="fullName" className="form-label">Full Name</label>
                                    <input style={{ textDecoration: "capitalize" }} type="text" className="form-control fullName" id="fullName" placeholder='Full Name' aria-describedby="emailHelp" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>

                                <div className="mb-3 col-lg-6 px-2">
                                    <label htmlFor="phoneNo" className="form-label">Phone No</label>
                                    <input type="number" className="form-control" id="phoneNo" placeholder='Mobile No' value={contact} onChange={(e) => setContact(e.target.value)} />
                                </div>
                            </div>

                            <div className='signup-inputs'>
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="phoneNo" className="form-label">Exam Type</label>
                                    <select className="form-select" aria-label="Default select example" value={examType} onChange={(e) => setExamType(e.target.value)}>
                                        <option value="civil services">Civil Services</option>
                                        <option value="neet">NEET</option>
                                        <option value="cuet">CUET</option>
                                        <option value="ugc-net">UGC-NET</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-lg-6 px-2">
                                    <label htmlFor="password" className="form-label">Location</label>
                                    {/* <select searchable className="form-select" aria-label="Default select example" value={city} onChange={(e) => setcity(e.target.value)}>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Up">Up</option>
                                        <option value="Gujurat">Gujurat</option>
                                    </select> */}
                                    <Select
                                        value={{ value: city, label: city }}
                                        onChange={(selectedOption) => setcity(selectedOption.value)}
                                        //onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                                        options={citiesArray?.map((city) => ({ value: city, label: city }))}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                // width: '100%',
                                                border: "none",
                                                // backgroundColor: 'transparent', // Adjust as needed
                                                // borderColor: '#17a2b8', // Adjust as needed
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='signup-inputs'>
                                <div className="mb-3 col-lg-6 ">
                                    <label htmlFor="email" className="form-label">Email id</label>
                                    <input type="email" className="form-control" id="email" placeholder='Your Email-ID' value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} />
                                </div>
                                <div className="mb-3 col-lg-6 px-2">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input type="password" className="form-control" id="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary login-btn py-2 border-0" type="button" onClick={handleRegistration}>REGISTER</button>
                            </div>
                        </form>
                        {/* Display error message if any */}
                        {error && <p className="text-danger">{error}</p>}
                    </div>
                    <div className='m-3 login-bottom'>
                        <div className="card-footer py-3 d-flex justify-content-center login-footer signup-footer">
                            Already have an Account? <Link className='list-group-item px-1 text-orange' to="/login">Login</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="login-list">
                <div className='login-box'>
                    <Link to="/home" className='login-link'>HOME</Link>
                    <Link to="/about" className='login-link'>ABOUT US</Link>
                    <Link to="#" className='login-link'>CIVILS</Link>
                    <Link to="#" className='login-link'>NEET</Link>
                    <Link to="#" className='login-link'>CUET</Link>
                    <Link to="#" className='login-link'>UGC-NET</Link>
                    <Link to="/daily-current-affairs/News" className='login-link'>CURRENT AFFAIRS</Link>
                    <Link to="/blogs" className='login-link'>BLOGS</Link>
                </div>
                {/* <div className='login2'>
                    <h2 className='text-white'>© 2022 - 2023 Insight Delhi</h2>
                </div> */}
                <div className='login3'>
                    <div>
                        <FaFacebookF />
                    </div>
                    <div>
                        <FaTelegramPlane />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
