import React, { useEffect, useState } from 'react';
import Civildropdown from './CivildropDown';

const Civil = () => {

  const civil = [
    {
      title: 'Home',
      links: [
        { label: 'About Us', url: '/about-us' },
        { label: 'Pattern Of Exam', url: '/about/pattern-of-exam' },
        { label: 'Syllabus', url: '/about/ias-exam-syllabus' },
        { label: 'Sample Notes', url: '/about/Sample-Notes-for-ias-exam' },
        { label: 'FAQs', url: '/about/frequently-asked-questions-about-ias-exam' },
        // Add more links for the 'Home' category
      ]
    },
    {
      title: 'GENERAL STUDIES',
      links: [
        { label: 'GS Foundation Course', url: `/course/gs-foundation-course-for-upsc` },
        { label: 'GS Prelims Course', url: `/course/GS-Prelims-Course` },
        { label: 'GS Main Course', url: `/course/GS-Mains-Course` },
        { label: 'Ethics, Aptitude And Integrity', url: `/course/Ethics-Aptitude-and-Integrity` },
        // Add more links for 'GENERAL STUDIES' category
      ]
    },
    {
      title: 'HISTORY',
      links: [
        { label: 'History Optional Online', url: `/course/History-Optional-Online` },
        { label: 'History Optional Offline', url: `/course/History-Optional-Offline` },
        { label: 'GS History And Culture', url: `/course/History-and-Culture` },
        { label: 'Art and Culture', url: `/course/Art-and-Culture-Course` },
        { label: 'Hist. Opt. Map Classes', url: `/course/history-optional` },
        // Add more links for 'HISTORY' category
      ]
    }



  ];

  const civil1 = [
    {
      title: '',
      links: []
    },
    {
      title: 'Test Series',
      links: [
        { label: 'GS Prelims Test Series', url: '/testseries/Prelims-Test-Series-for-upsc-2025' },
        { label: 'GS Pre-Cum-Mains', url: '/testseries/pre-cum-mains-test-series-for-upsc-2025' },
        { label: 'GS Mains Test Series', url: '/testseries/pre-cum-mains-test-series-for-upsc-2025' },
        {
          label: 'Hist. Opt. Test Series', url: '/testseries/History-Optional-Test-Series-for-upsc-mains-2025' },
        // Add more links for 'Test Series' category
      ]
    },
    {
      title: 'Success Mantra',
      links: [
        { label: 'PT Exam 2023 - Answer Key', url: '/pt-exam-answer-key' },
        { label: 'Mains Exam 2023', url: '/about/upsc-mains-2021' },
        { label: 'Topper\'s Strategy', url: '/about/ias-toppers-strategy' },
        { label: 'Strategy For Hist. Optional', url: '/about/strategy-for-history-optional' },
        // Add more links for 'Success Mantra' category
      ]
    },
    {
      title: 'Free Course',
      links: [
        { label: 'UPSC QUIZ', url: '/upsc-quiz' },
        { label: 'Art And Culture Book', url: '/art-culture-book' },
        { label: 'Book List For GS', url: '/about/Book-List-for-General-Studies' },
        { label: 'Book List For Hist. Opt', url: '/about/History-optional-Book-List-and-syllabus-for-ias-exam' },
        { label: 'Imp. Websites', url: '/about/important-websites-for-online-preparation-of-ias-exam' },
        // Add more links for 'Free Course' category
      ]
    },
    // Add more categories as needed following the same structure
  ]
  //Civil Drop Down Data Section End

  return (
    <div>
      <Civildropdown civil={civil} civil1={civil1} />
    </div>
  );
};

export default Civil;
