import React, { useState, useEffect } from 'react';
import "./Buttontab.css";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { BASE_URL1 } from '../../insightConfig/insightConfig';

function Buttontab({ componentName, setContentToShow, ContentToShow, showAlert, handlelocal }) {
    // Manage All button State
    const [activeButton, setActiveButton] = useState('In News'); //Track active button
    const [buttonNames, setButtonNames] = useState([]); //Manage All button Name Array
    const navigate = useNavigate();
    const [currenttab, setcurrenttab] = useState(()=>{
        const storedAuthToken = localStorage.getItem('currenttabarray');
        return storedAuthToken ? JSON.parse(storedAuthToken) : [];
      })

    useEffect(() => {
        
        if (componentName) {
            fetch(`${BASE_URL1}/current-affairs-category-names`)
              .then(response => response.json())
              .then(data => {
                  const carray = data.data || []
                setcurrenttab(data.data);
                localStorage.setItem('currenttabarray', JSON.stringify(carray));
                
              })
              .catch(error => {
                console.error('Error fetching data:', error);
              });
          }
        // Map component names to button labels
        const buttonLabels = {
            // 'currentaffairs': ['In News', 'Editorial', 'Yojana', 'Big Picture', 'Insight Radio'],
            'currentaffairs': currenttab,
            'Blog': ['Blogs', "Today's Special"],
            'Prelims': ["START"],
            'WithPrelimsQuit': ["CONTINUE"],
            'WithPrelims': ["REATTEMPT","VIEW REPORT"],
            'Cuet': ['CUET', 'CUET SECTION – IA', 'CUET SECTION – II', 'CUET SECTION – III', 'RESOURCES', 'PRACTICE QUIZ']
            // Add more component names and their associated button labels as needed
        };
        const defaultButtons = buttonLabels['civilHistory']; // Default to currentaffairs if componentName doesn't match
        const buttons = buttonLabels[componentName] || defaultButtons;
        // Manage Default State of Feature and Overview Content According to ComponentName
        switch (componentName) {
            case 'currentaffairs':
                setContentToShow('In News');
                break;
            case 'Blog':
                setContentToShow('Blogs');
                break;
            case 'PT MCQs':
                setContentToShow('PT MCQs');
                setActiveButton('PT MCQs');
                break;
            default:
                break;
        }
        setButtonNames(buttons);
        setActiveButton(buttons[0]?.toLowerCase().replace(/\s+/g, '')); 
    }, [componentName]);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName.toLowerCase().replace(/\s+/g, ''));
    };

    return (
        <>
            {/*Button tab Section Start */}
            <div className='buttontab-background'>
                {componentName !== 'Cuet' && componentName !== 'Blog' && componentName !== 'Prelims' && componentName !== 'WithPrelims' && componentName !== 'WithPrelimsQuit' &&
                    <div className='p-3'>
                        <div className="buttontab-Container">
                            {buttonNames.map((name, index) => (
                                <button
                                    key={index}
                                    className={`btn btn-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activebuttontab' : "bgtab-white"}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "START":
                                                    // Handle START accordingly
                                                    setContentToShow("START");
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'Prelims' && componentName !== 'WithPrelims' && componentName !== 'WithPrelimsQuit' &&
                    <div className='p-3'>
                        <div className="buttontab-Container">
                            {buttonNames.map((name, index) => (
                                <>
                                <button
                                    key={index}
                                    className={`btn btn-prelims-margin activebuttontabmcq`}
                                    type="button">PT MCQs</button>
                                <button
                                    key={index}
                                    className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'activebuttontabmcq' : 'bgtab-mcq'}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "START":
                                                    // Handle START accordingly
                                                    setContentToShow("START");
                                                    showAlert()
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                                    </>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'WithPrelims' && componentName !== 'Prelims' && componentName !== 'WithPrelimsQuit' &&
                    <div className='p-3'>
                        <div className="buttontab-Container">
                            <button
                                className={`btn btn-prelims-margin activebuttontabmcq`}
                                type="button">PT MCQs</button>
                            {buttonNames.map((name, index) => (
                                <>
                                <button
                                    key={index}
                                    className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'bg-Question-white' : 'bg-Question-white'}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "REATTEMPT":
                                                    // Handle START accordingly
                                                    setContentToShow("REATTEMPT");
                                                    showAlert()
                                                    handlelocal()
                                                    break;
                                                case "CONTINUE":
                                                    // Handle START accordingly
                                                    setContentToShow("CONTINUE");
                                                    showAlert()
                                                    break;
                                                case "VIEW REPORT":
                                                    // Handle START accordingly
                                                    setContentToShow("VIEW REPORT");
                                                    navigate('/CourseDescreption/CourseResult')
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                                    </>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'WithPrelimsQuit' && componentName !== 'Prelims' && componentName !== 'WithPrelims' &&
                    <div className='p-3'>
                        <div className="buttontab-Container">
                            <button
                                className={`btn btn-prelims-margin activebuttontabmcq`}
                                type="button">PT MCQs</button>
                            {buttonNames.map((name, index) => (
                                <>
                                <button
                                    key={index}
                                    className={`btn btn-prelims-margin ${activeButton === ContentToShow ? 'bg-Question-white' : 'bg-Question-white'}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "REATTEMPT":
                                                    // Handle START accordingly
                                                    setContentToShow("REATTEMPT");
                                                    showAlert()
                                                    handlelocal()
                                                    break;
                                                case "CONTINUE":
                                                    // Handle START accordingly
                                                    setContentToShow("CONTINUE");
                                                    showAlert()
                                                    break;
                                                case "VIEW REPORT":
                                                    // Handle START accordingly
                                                    setContentToShow("VIEW REPORT");
                                                    navigate('/CourseDescreption/CourseResult')
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                                    </>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'Cuet' &&
                    <div className='p-3'>
                        <div className="buttoncuettab-Container">
                            {buttonNames.map((name, index) => (
                                <button
                                    key={index}
                                    className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activecuetbuttontab' : "bgcuettab-white"}`}
                                    onClick={
                                        () => {
                                            handleButtonClick(name)
                                            switch (name) {
                                                case 'In News':
                                                    // Handle In News accordingly
                                                    setContentToShow('In News');
                                                    break;
                                                case 'Editorial':
                                                    // Handle Editorial accordingly
                                                    setContentToShow('Editorial');
                                                    break;
                                                case 'Yojana':
                                                    // Handle Yojana accordingly
                                                    setContentToShow('Yojana');
                                                    break;
                                                case 'Big Picture':
                                                    // Handle Big Picture accordingly
                                                    setContentToShow('Big Picture');
                                                    break;
                                                case 'Insight Radio':
                                                    // Handle Insight Radio accordingly
                                                    setContentToShow('Insight Radio');
                                                    break;
                                                case 'Blogs':
                                                    // Handle Blogs accordingly
                                                    setContentToShow('Blogs');
                                                    break;
                                                case "Today's Special":
                                                    // Handle Today's Special accordingly
                                                    setContentToShow("Today's Special");
                                                    break;
                                                case "PT MCQs":
                                                    // Handle PT MCQs accordingly
                                                    setContentToShow("PT MCQs");
                                                    break;
                                                case "START":
                                                    // Handle START accordingly
                                                    setContentToShow("START");
                                                    break;
                                                case "CUET":
                                                    // Handle START accordingly
                                                    setContentToShow("CUET");
                                                    // setcuetlink("#")
                                                    break;
                                                case "CUET SECTION – IA":
                                                    // Handle START accordingly
                                                    setContentToShow("CUET SECTION – IA");
                                                    // setcuetlink("/cuet/prelims-test-series-for-upsc")
                                                    navigate('/cuet/prelims-test-series-for-upsc')
                                                    break;
                                                case "CUET SECTION – II":
                                                    // Handle START accordingly
                                                    setContentToShow("CUET SECTION – II");
                                                    // setcuetlink("/cuet/Pre-Cum-Mains-Test-Series")
                                                    navigate('/cuet/Pre-Cum-Mains-Test-Series')
                                                    break;
                                                case "CUET SECTION – III":
                                                    // Handle START accordingly
                                                    setContentToShow("CUET SECTION – III");
                                                    // setcuetlink("#")
                                                    break;
                                                case "RESOURCES":
                                                    // Handle START accordingly
                                                    setContentToShow("CUET SECTION – III");
                                                    // setcuetlink("#")
                                                    break;
                                                case "PRACTICE QUIZ":
                                                    // Handle START accordingly
                                                    setContentToShow("PRACTICE QUIZ");
                                                    // setcuetlink("#")
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                    }
                                    type="button">{name}</button>
                            ))}
                        </div>
                    </div>
                }
                {componentName === 'Blog' &&
                <div className='pt-3'>
                    <div className="buttoncuettab-Container">
                        {buttonNames.map((name, index) => (
                            <button
                                key={index}
                                className={`btn btncuet-margin ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activeblogbuttontab' : "bgblogtab-white"}`}
                                onClick={
                                    () => {
                                        handleButtonClick(name)
                                        switch (name) {
                                            case 'In News':
                                                // Handle In News accordingly
                                                setContentToShow('In News');
                                                break;
                                            case 'Editorial':
                                                // Handle Editorial accordingly
                                                setContentToShow('Editorial');
                                                break;
                                            case 'Yojana':
                                                // Handle Yojana accordingly
                                                setContentToShow('Yojana');
                                                break;
                                            case 'Big Picture':
                                                // Handle Big Picture accordingly
                                                setContentToShow('Big Picture');
                                                break;
                                            case 'Insight Radio':
                                                // Handle Insight Radio accordingly
                                                setContentToShow('Insight Radio');
                                                break;
                                            case 'Blogs':
                                                // Handle Blogs accordingly
                                                setContentToShow('Blogs');
                                                break;
                                            case "Today's Special":
                                                // Handle Today's Special accordingly
                                                setContentToShow("Today's Special");
                                                break;
                                            case "PT MCQs":
                                                // Handle PT MCQs accordingly
                                                setContentToShow("PT MCQs");
                                                break;
                                            case "START":
                                                // Handle START accordingly
                                                setContentToShow("START");
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }
                                type="button">{name}</button>
                        ))}
                    </div>
                    </div>
                }
            </div>
            {/*Button tab Section End */}
        </>
    )
}

export default Buttontab;