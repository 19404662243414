import { v4 as uuidv4 } from 'uuid';
export function getUniqueId() {
    return uuidv4()?.replace(/-/g, '');
}

export const selectNewSubjectAndSectionRandomly = (subjects) => {
    const previousSubjectURL = localStorage.getItem('previousSubjectURL');
    let filteredSubjects;
    if (previousSubjectURL) {
        filteredSubjects = subjects?.filter((subject) => subject.subjectURL != previousSubjectURL);
    } else {
        filteredSubjects = subjects;
    }
    let subjectToDisplay = filteredSubjects[Math.floor(Math.random() * filteredSubjects?.length)];
    if (subjectToDisplay) {
        let sections = subjectToDisplay?.sections;
        let selectedSection = sections[Math.floor(Math.random() * sections?.length)];
        return { subjectId: subjectToDisplay.subjectId, subject: subjectToDisplay.subject, subjectURL: subjectToDisplay.subjectURL, sectionId: selectedSection.sectionId, section: selectedSection.section, sectionURL: selectedSection.sectionURL };
    }
}