import React, { useState, useEffect, useRef } from 'react';
// E: \Tech - Xtend\InsightFrontend\Raaz_Work_Insight\src\jsx\QuizComponent\QuestionLayout\QuestionLayout.css
import "./Questionpreview.css";

import { BASE_URL } from '../../../insightConfig/insightConfig';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { FiClock } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { useQuizDetails } from '../../Homequiz/QuizContext';
import { selectNewSubjectAndSectionRandomly } from '../../../commonServices/commonService';
import { useNavigate } from 'react-router-dom';


function Questionpreview2() {
  const navigate = useNavigate();
  const uniqueUserId = localStorage.getItem('uniqueUserId');
  const { quizDetails, setQuizDetails, updateQuizProperty } = useQuizDetails();
  const [currentQuestion, setCurrentQuestion] = useState({});
  const { subjects, subject, section, subjectId, sectionId, questions, currentQuestionIndex, questionIdParams } = quizDetails || {};



  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex >= 0) {
      const question = questions[currentQuestionIndex];
      if (question?.Sn) {
        setCurrentQuestion(question);
        // // Async handler for marking the question in DB
        // const markQuestion = async (sn) => {
        //   try {
        //     await markQuestionAsVisitedInDB(sn);
        //   } catch (error) {
        //     console.error("Error marking question as visited:", error);
        //   }
        // };

        // markQuestion(question.Sn);
      }
    }
  }, [currentQuestionIndex, questions]);

  console.log(currentQuestionIndex);
  useEffect(() => {
    const fetchData = async () => {
      // console.log('called');
      const response = await axios.get(BASE_URL + `/getQuizQuestions/${subjectId}/${sectionId}/${uniqueUserId}`);
      const questions = response.data.questions;
      if (!questions.length) {
        handleNextSubject();
        return
      }

      let currentQuestionIndex;
      if (questionIdParams) {
        currentQuestionIndex = questions?.findIndex(obj => obj.QuizID == questionIdParams);
        // console.log(currentQuestionIndex);
      } else {
        currentQuestionIndex = 0;
      }
      setQuizDetails((preData) => ({
        ...preData, questions, currentQuestionIndex
      }))
    }
    if (subjectId && sectionId) fetchData();
  }, [subjectId, sectionId])
  // console.log(currentQuestion);

  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [loadNextQuestion, setLoadNextQuestion] = useState(false)
  const [visitedQuestionsIDs, setVisitedQuestionsIDs] = useState([])
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1);
  const [selectedOption, setSelectedOption] = useState('')
  const [showDescription, setShowDescription] = useState(false)

  // console.log(visitedQuestionsIDs)
  //for getting previous question
  const prevStateRef = useRef();
  // useEffect(() => {
  //   prevStateRef.current = selectedQuestion;
  // }, [selectedQuestion])


  // console.log(prevStateRef, selectedQuestion)

  const markQuestionAsVisitedInDB = async (questionSn) => {
    // setVisitedQuestionIDsArray((prev) => [...prev, questionSn]);
    try {
      await axios.put(`${BASE_URL}/markQuestionAsVisitedInDB/${uniqueUserId}/${questionSn}`)
    } catch (error) {
      console.error('Error ', error);
    }
  }

  // console.log(notVisitedQuestions)
  // useEffect(() => {
  //   prevStateRef.current = selectedQuestion;
  //   let selected;
  //   if (suggestedQuestion?.Sn) {
  //     selected = suggestedQuestion;
  //   } else {
  //     let notVisitedQuestions = quizQuestions.filter((question) => !visitedQuestionsIDs.includes(question.Sn))
  //     if (!notVisitedQuestions.length) {
  //       setNewSubject();
  //       return
  //       // if (selectedSectionIndex < sectionsLength) {
  //       //   setSelectedSectionIndex(selectedSectionIndex + 1);
  //       // } else {
  //       //   setNewSubject();
  //       // }
  //       // return
  //     }
  //     selected = notVisitedQuestions[Math.floor(Math.random() * notVisitedQuestions?.length)]
  //   }
  //   if (selected?.Sn) {
  //     setSelectedQuestion(selected);
  //     setCurrentQuestion(selected)
  //     setVisitedQuestionsIDs((prev) => [...prev, selected?.Sn])
  //     markQuestionAsVisitedInDB(selected?.Sn)
  //   }

  //   setSelectedOption('')

  // }, [])

  // useEffect(() => {
  //   console.log('enetr in use Effect')
  //   if (selectedQuestionNumber && quizQuestions) {
  //     let selectedQuestion = quizQuestions[selectedQuestionNumber - 1]
  //     setSelectedQuestion((preData) => ({ ...preData, ...selectedQuestion }))
  //     if (selectedQuestion?.Sn) {
  //       markQuestionAsVisitedInDB(selectedQuestion?.Sn)
  //     }
  //   }
  //   setSelectedOption('')
  // }, [selectedQuestionNumber, quizQuestions])

  // console.log(selectedQuestionNumber)

  const renderhtml = (paragraphs) => {
    return <i className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  // const handleexplain = ()=>{
  //   setExplain(!Explain)
  // }




  const checkCondition = (optionName) => {
    if (!selectedOption || selectedOption != optionName) return ' p-0 bg-light ps-2 pe-2 ';

    if (selectedOption == optionName) {
      if (selectedOption === currentQuestion?.Answer) return 'isgreen p-0 bg-light ps-2 pe-2'
      return 'isred p-0 bg-light ps-2 pe-2'
    }
  }

  const selectStatement = (correctAnswer) => {
    if (correctAnswer == 'A') return 1;
    if (correctAnswer == 'B') return 2;
    if (correctAnswer == 'C') return 3;
    return 4;
  }



  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      updateQuizProperty('currentQuestionIndex', currentQuestionIndex - 1);
      setSelectedOption('');
    }
    // setCurrentQuestion(prevStateRef.current)
    // setSelectedQuestion(prevStateRef.current)
  }
  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      updateQuizProperty('currentQuestionIndex', currentQuestionIndex + 1);
      setSelectedOption('');
    }
  }
  const handleNextSubject = () => {
    const { subjectId, subject, subjectURL, sectionId, section, sectionURL } = selectNewSubjectAndSectionRandomly(subjects);
    navigate(`/upsc-quiz/${subjectURL}/${sectionURL}`);
  }

  return (

    <>
      <div style={{ backgroundColor: '#f7e8e3' }}>
        <h5 className='text-danger fw-bold p-2'> <span className='me-2'>{subject}</span> &rarr;<span className='ms-2'>{section}</span> </h5>
      </div>
      {
        questions?.length ?
          <div className={(selectedOption && selectedOption === currentQuestion?.Answer) ? 'card mb-3 questionpreviewpadding winner-list' : 'card mb-3 questionpreviewpadding'} style={{ boxShadow: "0 3px 10px rgba(0,0,0,0.3)" }}>

            <p className='mb-0'><b>Q. {renderhtml(currentQuestion?.Question)}</b></p>
            <ul className='answer-list mt-0 pt-0'>
              <li className={checkCondition('A')} onClick={() => setSelectedOption('A')} >
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    className='me-2 mb-1'
                    name='option'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_1`}
                    checked={selectedOption?.includes('A')}

                  />
                  <label className='pt-3' htmlFor={`option_1`}>
                    <b className='d-flex'>(A) {renderhtml(currentQuestion?.OptionA)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != currentQuestion?.Answer && selectedOption === 'A') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === currentQuestion?.Answer && selectedOption === 'A') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('B')} onClick={() => setSelectedOption('B')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_2`}
                    checked={selectedOption?.includes('B')}

                  />
                  <label htmlFor={`option_2`} className='pt-3'>
                    <b className='d-flex'>(B) {renderhtml(currentQuestion?.OptionB)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != currentQuestion?.Answer && selectedOption === 'B') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === currentQuestion?.Answer && selectedOption === 'B') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('C')} onClick={() => setSelectedOption('C')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_3`}
                    checked={selectedOption?.includes('C')}

                  />
                  <label htmlFor={`option_3`} className='pt-3'>
                    <b className='d-flex'>(C) {renderhtml(currentQuestion?.OptionC)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != currentQuestion?.Answer && selectedOption === 'C') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === currentQuestion?.Answer && selectedOption === 'C') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('D')} onClick={() => setSelectedOption('D')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_4`}
                    checked={selectedOption?.includes('D')}

                  />
                  <label htmlFor={`option_4`} className='pt-3'>
                    <b className='d-flex'>(D) {renderhtml(currentQuestion?.OptionD)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != currentQuestion?.Answer && selectedOption === 'D') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === currentQuestion?.Answer && selectedOption === 'D') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
            </ul>





            <div className='p-3 d-flex justify-content-between flex-wrap'>
              <div className='col-lg-4 py-1 col-md-8 col-sm-8 d-flex justify-content-center'>
                <button /* disabled={!prevStateRef?.current?.Sn} */ disabled={currentQuestionIndex === 0} type="button" className="btn bg-quiz-gray btn-md me-2" onClick={handlePrevQuestion}>Previous</button>
              </div>
              <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex justify-content-center'>
                <button type="button" className="btn bg-quiz-green btn-md" onClick={() => setShowDescription(!showDescription)}>Explanation</button>
              </div>
              <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex justify-content-center'>
                {
                  currentQuestionIndex === questions?.length - 1
                    ?
                    <button type="button" /* disabled={currentQuestionIndex === questions?.length - 1} */ className="btn bg-quiz-white px-4 btn-md" onClick={handleNextSubject}>Choose Next Subject</button>
                    :
                    <button type="button" /* disabled={currentQuestionIndex === questions?.length - 1} */ className="btn bg-quiz-white px-4 btn-md" onClick={handleNextQuestion}>Next</button>

                }
              </div>
            </div>

            {showDescription &&
              <div className='mt-3 p-3 bg-skyblue'>
                <p><b>Explanation:</b></p>
                <p><b>ANSWER: ({currentQuestion.Answer})</b></p>
                <p><b>Statement {selectStatement(currentQuestion.Answer)} is correct</b></p>
                <p className='bg-lightgray p-3'>{renderhtml(currentQuestion.Description)}</p>
                {/* <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>


          <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
          <p><b>Statement 2 is not correct</b></p>
          <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
          <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
          <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p> */}

              </div>}
            {/* {(Explain) && <div className='mt-3 p-3 bg-skyblue'>
          <p><b>Explanation:</b></p>
          <p><b>ANSWER: (A)</b></p>
          <p><b>Statement 1 is correct</b></p>
            <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
            <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
            <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
          <p><b>Statement 2 is not correct</b></p>
            <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
            <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
            <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
         
        </div>} */}

          </div> :
          <>
            {/* {!loadingQuestions && <h4 style={style}>You have visited all the Questions in this section. Please try another one.</h4>} */}
          </>
      }

      {/* {finish && <ResultPreview queslength={queslength} userstorage={userstorage} unanswereddata={unanswereddata} />} */}
    </>
  );
}

export default Questionpreview2;




