import React from 'react';
import { IoIosArrowRoundForward } from "react-icons/io";
import Civillogo from "../../../Image/dropdown.png";
import { Link } from 'react-router-dom';

const DropdownCategory = ({ title, links }) => {
    return (
        // Civil Drop Down List Section Start 
        <>
            <div className='col-lg-3 col-md-4 col-sm-4 civil-tablet-menu1'>
                <h2 className='fs-6 fw-bold drops'>{title}</h2>
                <ul className='ps-0'>
                    {links.map((link, index) => (
                        <React.Fragment key={index}>
                            <li className='list-group-item civilpadding'>
                                <Link className='list-group-item' to={link.url} state={{ isEnrolled: link.isEnrolled }} key={index}>
                                    <IoIosArrowRoundForward className='fs-4 pb-1' /> {link.label}
                                </Link>
                            </li>
                            <li className='list-group-item'><hr style={{ margin: "0.2vmax" }} className="dropdown-divider" /></li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
            <div className='col-lg-3 civil-tablet-menu2'>
                <h2 className='fs-6 fw-bold drops'>{title}</h2>
                <ul className='ps-0'>
                    {links.map((link, index) => (
                        <React.Fragment key={index}>
                            <li className='list-group-item civilpadding'>
                                <Link className='list-group-item' to={link.url} state={{ isEnrolled: link.isEnrolled }} key={index}>
                                    <IoIosArrowRoundForward className='fs-4 pb-1' /> {link.label}
                                </Link>
                            </li>
                            <li className='list-group-item'><hr style={{ margin: "0.2vmax" }} className="dropdown-divider" /></li>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </>
        // Civil Drop Down List Section End
    );
};

const Civildropdown = ({ civil, civil1 }) => {
    return (
        // Civil Drop Down Section Start
        <div className='Container background-submenu civilground-submenu'>
            <div className='row'>
                <div className='col-lg-3'>
                    <img src={Civillogo} alt='' className='drop-image' />
                </div>
                {civil.map((category, index) => (
                    <DropdownCategory key={index} title={category.title} links={category.links} />
                ))}
            </div>
            <div className='row mt-4'>
                {civil1.map((category, index) => (
                    <DropdownCategory key={index} title={category.title} links={category.links} />
                ))}
            </div>
        </div>
        // Civil Drop Down Section End
    );
};

export default Civildropdown;
