import React, { useContext, useEffect, useState } from 'react';
import "../BlogPage/BlogPage.css";
import "./Homequiz.css"
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import BlogPage1 from '../BlogPageComponent/BlogPage1';
import BlogPage2 from '../BlogPageComponent/BlogPage2';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL, BASE_URL1 } from '../../insightConfig/insightConfig';
import Homequizmenu from './Homequizmenu';
import QuestionLayoutQuiz from '../QuizComponent/QuestionLayout/QuestionLayoutQuiz';
import axios from 'axios';
import { useQuizDetails } from './QuizContext';

// function Homequiz() {
function Homequiz() {
    const { dynamicSubjectURL, dynamicSectionURL, dynamicQuestionID } = useParams();
    // console.log(dynamicQuestionID);
    const navigate = useNavigate();
    const { quizDetails, setQuizDetails } = useQuizDetails();
    const { subjects, subjectURL, sectionURL, currentQuestionIndex, questions } = quizDetails || {};
    // console.log(quizDetails);
    //states
    // const [subjects, setsubjects] = useState([]);
    const setSelectedSubjectData = (subjects) => {
        const selectedSubject = subjects.find((obj) => obj.subjectURL == dynamicSubjectURL);
        const selectedSection = selectedSubject?.sections?.find((obj) => obj.sectionURL == dynamicSectionURL);
        setQuizDetails((preData) => ({
            ...preData, subjects, subject: selectedSubject?.subject, section: selectedSection?.section, subjectURL: dynamicSubjectURL, sectionURL: dynamicSectionURL, subjectId: selectedSubject?.subjectId, sectionId: selectedSection?.sectionId, questionIdParams: dynamicQuestionID
        }))
    }

    useEffect(() => {
        localStorage.setItem('previousSubjectURL', dynamicSubjectURL);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BASE_URL1}/getSubjectAndSections`);
                let subjects = response.data.data;
                setSelectedSubjectData(subjects);

            } catch (error) {
                console.error('Error fetching data:', error);
            }

        }
        if (subjects?.length) {
            setSelectedSubjectData(subjects);
        } else {
            fetchData();
        }

        // return () => {
        //     setQuizDetails((preData) => ({
        //         ...preData, subjectURL: '', sectionURL: ''
        //     }))
        // }

    }, [dynamicSubjectURL, dynamicSectionURL]);

    useEffect(() => {
        if (questions.length && currentQuestionIndex >= 0) {
            let currentQuestion = questions[currentQuestionIndex]
            navigate(`/upsc-quiz/${subjectURL}/${sectionURL}/${currentQuestion?.QuizID}`);
        }
    }, [currentQuestionIndex, questions])




    return (
        <>
            <div className='BlogPage-background'>
                <div className='BlogPage-box1'>
                    <h1>INSIGHT UPSC QUIZ</h1>
                </div>
            </div>
            <div className='p-3'>
                <div className='container-fluid quizbackground'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12 col-sm-12'>
                            {/* <QuestionLayout2/> */}
                            <QuestionLayoutQuiz />
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <Homequizmenu subjects={subjects} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homequiz;