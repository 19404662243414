import React, { useEffect, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview2 from '../Questionpreview/Questionpreview2';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import axios from 'axios';
import { BASE_URL1 } from '../../../insightConfig/insightConfig';
import { useQuizDetails } from '../../Homequiz/QuizContext';

function QuestionLayout2() {
  // console.log(quizDetails);
  const [quizQuestions, setQuizQuestions] = useState([])
  // const [notVisitedQuestions, setNotVisitedQuestions] = useState([])
  const [suggestedQuestions, setSuggestedQuestions] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [visitedQuestionIDsArray, setVisitedQuestionIDsArray] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [suggestedQuestion, setSuggestedQuestion] = useState({})
  // console.log(quizQuestions, QuizSubjectid, selectedSection, selectedSubject)
  // console.log(currentQuestion)

  const uniqueUserId = localStorage.getItem('uniqueUserId');


  // useEffect(() => {
  //   if (QuizSectionid && QuizSubjectid) {
  //     axios.get(`${BASE_URL1}/getQuizQuestions/${QuizSubjectid}/${QuizSectionid}/${uniqueUserId}`)
  //       .then(response => {
  //         setQuizQuestions(response.data.data)

  //         setLoadingQuestions(false)
  //         // setqueslength(response.data.data.questionsArray);
  //         // setsubjectname(response.data.data.subject);
  //         // setsectionname(response.data.data.section); 
  //         // setQuizQuestionurl(response.data.data.questionsArray[currentQuestionIndex].titleURL)
  //       })
  //       .catch(error => {
  //         setLoadingQuestions(false)
  //         console.error('Error fetching data:', error);
  //       });
  //   }
  // }, [QuizSubjectid, QuizSectionid]);


  useEffect(() => {
    let notVisitedQuestions = quizQuestions.filter((question) => question.Sn !== currentQuestion.Sn && !visitedQuestionIDsArray.includes(question.Sn))
    // setNotVisitedQuestions(notVisitedQuestions)
    if (notVisitedQuestions.length > 10) {
      setSuggestedQuestions(notVisitedQuestions.slice(0, 10))
    } else {
      setSuggestedQuestions(notVisitedQuestions)
    }
  }, [visitedQuestionIDsArray])



  const renderhtml = (paragraphs) => {
    return <i className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };
  // console.log(selectedSection, selectedSubject)
  return (
    <>
      <div className='container-fluid p-3 pt-0'>
        <div className='row'>
          <div className='col-lg-12' >
            <Questionpreview2 />
            <p className='py-2'><b>More Questions Selected Just For You. Attempt Now!</b></p>

            <div className='mt-3 card questionpreviewpadding' style={{ boxShadow: "0 3px 10px rgba(0,0,0,0.3)" }} >
              {suggestedQuestions.map((question, index) => (
                <p onClick={() => setSuggestedQuestion(question)} key={index} className='p-3 suggestedquestion'><b>{index + 1}. {renderhtml(question.Question)}</b></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
  // }
}

export default QuestionLayout2